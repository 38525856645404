.note-input {
  background-color: transparent;
  border: 0;
  width: 100%;
  resize: none;
  padding: $gutter 0;
  &::placeholder {
    color: $primary;
    font-size: 0.9rem;
  }

  &:focus {
    outline: transparent;
  }
}

.email-input {
  .flex-input {
    display: flex;
    align-items: center;
    label {
      margin-bottom: 0;
      padding-right: $gutter;
    }
    input,
    textarea {
      flex: 1 1 auto;
    }
  }
  .addresses-input{
    display: flex;
    margin-right: 1rem;
    label {
      padding: 0.5rem 0.5rem 0 0;
    }
    .email-addresses {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      .email-address-item {
        margin: 3px;
        background-color: #3b86ff;
        color: #ffffff;
        font-size: 0.9rem;
        cursor: pointer;
        padding: 2px 21px 2px 2px;
        display: inline-block;
        position: relative;
        border-radius: 0.25rem;
        .remove-button {    
          color: #ffffff;
          padding: 3px 7px;
          position: absolute;
          right: 0;
          top: -2px
        }
      }
      input {
        border: none;
        background-color: transparent;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
.document-view {
  iframe { 
    flex-grow: 1;
    border: darken($underlineGrey, 10%) 3px inset;
    margin: 0;
    padding: 0;
  }  
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;  
}
