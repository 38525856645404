@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,700;1,200;1,300&display=swap");

$primary: #3b86ff;
$danger: #ef626c;
$defaultColour: #edf0f5;
$success: #3ac94b;

$background: #f4f4f8;
$backgroundSecondary: #3b3b53;

$white: #ffffff;
$black: #000501;

$textDark: #4d565c;
$textLighter: #808495;
$textGrey: #a4afb7;

$underlineGrey: #c1c4c7;
$inputBorderGrey: #ced4da;

$gutter: 15px;
$navbarHeight: 50px;

@import "~bootstrap/scss/bootstrap.scss";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "react-dropzone-uploader/dist/styles.css";
@import "./imports/actions";
@import "./imports/bubble";
@import "./imports/buttons";
@import "./imports/cases";
@import "./imports/charts";
@import "./imports/dashboard";
@import "./imports/forms";
@import "./imports/layout";
@import "./imports/loading";
@import "./imports/multi-input";
@import "./imports/navbar";
@import "./imports/scanX";
@import "./imports/sidenav";
@import "./imports/tabs";
@import "./imports/teamq";
@import "./imports/typeahead";
@import "./imports/user-guide";

:root {
  --font-family-sans-serif: "Source Sans Pro", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

body {
  font-family: var(--font-family-sans-serif);
  background-color: $background;
  font-weight: 400;
}

.MuiTableCell-root {
  // vertical-align: top !important;
  padding: 16px 10px !important;
}

.MuiTableCell-paddingCheckbox {
  padding: 6px 0 6px 4px !important;
}

.labeled-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  // font-size: .9rem;
  li {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    .list-title {
      width: 100%;
    }
    .list-item {
      flex: 1 1 auto;
      color: rgba(255, 255, 255, 0.5);
      font-weight: 300;
    }
  }
}

.MuiTableRow-root.Mui-selected {
  background-color: #f7f7f9 !important;
}

a:hover {
  cursor: pointer;
}

button {
  background-color: unset;
  border: 0;
  padding: 0;
}

.none-found {
  padding-top: 30px;
  text-align: center;
  font-size: 1.3rem;
}

.flex-filters {
  display: flex;
  .filter-label {
    flex: 1 1 auto;
  }
  .delete-button {
    float: right;
    i {
      color: $danger;
    }
  }
}

.add-filter {
  // background-color: $primary;
  display: inline-block;
  position: relative;
  .add-filter-dropdown {
    // background-color: $danger;
    width: 219px;
    margin-left: 10px;

    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);

    z-index: 99;
    position: absolute;
    left: 100%;
    top: 0;

    ul {
      list-style: none;
      padding-left: 0;
      li {
        display: block;
        button {
          background-color: $white;
          display: block;
          width: 100%;
          text-align: left;
          padding: 5px 10px;
          &:hover {
            background-color: $background;
          }
        }
      }
    }
  }
}

.title {
  font-size: 1.1rem;
  position: relative;
  margin-bottom: 10px;
  a {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.nofocus {
  &:focus {
    border: 0;
    outline: none;
  }
}

.settings-title {
  font-size: 1.3rem;
  font-weight: 500;
}

.settings-existing-info {
  height: auto;
  min-height: 6rem;
  background-color: $white; 
}

.page-title {
  font-size: 1.4rem;
  margin: 25px 0 10px 0;
}

.sidenav-menu {
  list-style: none;
  padding-left: 0;
  margin: -10px -15px 0 -15px;
  li {
    a {
      display: block;
      width: 100%;
      background-color: darken($backgroundSecondary, 5%);
      padding: 10px $gutter;
      margin-bottom: 2px;
      color: $white;

      i {
        padding-right: $gutter;
      }
      &:hover {
        text-decoration: none;
        background-color: darken($backgroundSecondary, 7%);
      }
    }
  }
}

.attachment-pill {
  background-color: $primary;
  border-radius: 20px;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8rem;
  line-height: 1em;
  margin-right: 5px;
  padding-right: 21px;
}

.notification-count {
  background-color: $primary;
  font-size: 0.65rem;
  color: $white;
  padding: 1px 6px;
  font-weight: 700;
  border-radius: 100%;
  position: absolute;
  left: 80%;
  top: 11px;
  transform: translateX(-50%);
}

.warning {
  color: #ffc107;
  margin-right: 5px;
}

.spam-warning {
  color: #ffc107;
  margin-left: 7px;
}

.alert-warning {
  background-color: #ffc107;
  color: #856404;
}

.warning-link {
  float: right;
  color: darken($primary, 20%);
  // font-weight: bolder;4
}

.errors-list {
  font-size: 1rem;
  // margin-top: -29px;
  // margin-bottom: 5px;
}

.react-datepicker-wrapper {
  display: block !important;
}

.emergency-warning {
  color: $danger;
}

.settings-dropzone {
  margin-bottom: 10px;
}

ul {
  list-style: none;
}